.main-panel {
  &, &[data="red"]{
    border-color: $danger;
  }

  .content {
    padding: 78px 30px 30px 280px;
    min-height: calc(100vh - 70px);
  }
}
@media screen and (max-width: 768px){
  .main-panel .content {
      padding-left: 15px;
      padding-right: 15px;
  }
}
.logo-img{
  width: 80%;
  // height: 100px;
  display: block;
  // margin-left: 2px;
  // position: absolute;
  // top: 12px;
  margin: 5px;
    img{
        width: 100%;
        height: auto;
        max-height: 100%;
    }
}
.sidebar, .off-canvas-sidebar{
  &, &[data="red"]{
    @include linear-gradient($danger-states, $danger);
  }
  &[data="primary"]{
    @include linear-gradient($primary-states, $primary);
  }
}
@media screen and (max-width: 991px){
  .main-panel {
      .content {
      padding-left: 30px !important;
    }
  }
  .fixed-plugin .dropdown-toggle:after{
    display: none;
  }
}
